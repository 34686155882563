import React from 'react';
import Layout from '../components/layout';
import { Link, graphql, useStaticQuery } from 'gatsby';

import tableStyles from './cards.module.scss'

export const query = graphql`
    query {
        allHorseRankingsCsv {
            nodes {
              mu
              name
            }
          }
    }
`

const HorseRatings = (props) => {
    console.log(props.nodes)
    return (
        <Layout>
            <h1 style = {{textAlign: 'center'}}>Horse Racing - Individual Horse Ratings</h1>
            <p style = {{textAlign: 'center', paddingLeft: '10%', paddingRight: '10%'}}>
                I've begun to use a ranking algorithm similar to the one that is used to rank chess players to help me understand the "class" of a particular
                horses.  The results are listed below
            </p>
            <hr></hr>  
                <h2>United States</h2>       
                <table className={tableStyles.table}>
                    <thead>
                        <tr>
                            <th>name</th>
                            <th>rating</th>
                        </tr>
                    </thead>
                    {props.data.allHorseRankingsCsv.nodes.map((horse) => {
                    return(
                        <tbody>
                            <tr>
                                <td>{horse.name}</td>
                                <td>{horse.mu}</td>
                            </tr>
                        </tbody>
                    )
                })}
                </table>        
        </Layout>
    )
}

export default HorseRatings